/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { connect } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import * as Types from '../api/definitions';
import * as users from '../redux-modules/users';
import UsersList from '../components/UsersList';
import PageNotFound from '../components/PageNotFound';
import { updateConfiguration } from '../api/api';
import { domainPath } from '../App';
export interface ConfigurationContainerState {
    isLoading: boolean;
    error: string;
    hasError: boolean;
}

export interface ConfigurationContainerProp extends ContainerProps, WithSnackbarProps {
    getUsers: () => Promise<void>;
    createUsers: (users: Types.Users, is_accessToken: any) => Promise<void>;
    updateUsers: (users: Types.Users, is_accessToken: any) => Promise<void>;
    deleteUsers: (users: Types.Users, is_accessToken: any) => Promise<void>;
    getRoles: (is_accessToken: any) => Promise<void>;
    getAvailableUsers: (users: Types.Users) => Promise<void>;
    
}

export class ConfigurationContainer extends React.Component<ConfigurationContainerProp, ConfigurationContainerState> {
    constructor(props: ConfigurationContainerProp) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        return {
            isLoading: false,
            hasError: false,
            error: '',
            config_update_response: null
        };
    }

    saveConfiguration = async (config: Types.Configuration) => {
        this.setState({ isLoading: true });
        try {
            await updateConfiguration(config);
            this.props.enqueueSnackbar('Configuration Data saved successfully.');
        } catch (error) {
            this.props.enqueueSnackbar('An error occurred while saving configuration');
        }
        this.setState({ isLoading: false });
    };

    async componentDidMount() {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        this.props.closeSnackbar();
        this.setState({ isLoading: true });
        try {
            await this.props.getRoles(is_accessToken);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
        await this.props.getUsers();
        this.setState({ isLoading: false });
        // await  this.props.getReferral();
        // await this.props.getPrograms();
        // await this.props.getLocations();
    }

    render() {
        const {
            users: usersState,
            createUsers,
            updateUsers,
            deleteUsers,
            getAvailableUsers,
            getRoles
        } = this.props;

        const rolesList = (usersState && usersState.rolesList) || [];
        const availableUsersList = (usersState && usersState.availableUsersList) || [];
        const usersList = (usersState && usersState.usersList) || [];
        const {  user } = this.props;
        const role_type: any = user && user.user && user.user.role_type;
        const header_color: any = user && user.user && user.user.header_color;
        if (['consultant', 'coordinator'].includes(role_type?.toLowerCase())) {
            return (
                <PageNotFound />
            );

        } else {
            return (
                <UsersList
                    usersList={usersList}
                    headerColor={header_color}
                    availableUsersList={availableUsersList}
                    rolesList={rolesList}
                    {...this.state}
                    createUsers={createUsers}
                    updateUsers={updateUsers}
                    deleteUsers={deleteUsers}
                    getAvailableUsers={getAvailableUsers}
                    getRoles={getRoles}
                />
            )

        }


    }
}

const mapStateToProps = (state: AppState) => {
    return {
        referral: state.referral,
        users: state.users,
        user: state.user,
        program: state.program,
        programLocation: state.programLocation
    };
};

const mapDispatchToProps = {
    getUsers: users.actions.getUsers,
    createUsers: users.actions.createUsers,
    updateUsers: users.actions.updateUsers,
    deleteUsers: users.actions.deleteUsers,
    getAvailableUsers: users.actions.getAvailableUsers,
    getRoles: users.actions.getRoles
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(ConfigurationContainer));
