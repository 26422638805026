import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createBrowserHistory } from 'history';
import App from './App';
import configureStore from './redux-modules/configureStore';
import VersionChecker from './VersionChecker';

export const { store, persistor } = configureStore(createBrowserHistory());

const Root = () => {
  
    return (
      <>
        <App />
      </>
    );
  };

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Router>
                    <VersionChecker/>
                    <Root />
                </Router>
            </MuiPickersUtilsProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);